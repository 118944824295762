import React, {useEffect, useState} from "react";
import CircleLoc from "../../CircleLoc/CircleLoc";
import ContainerDirection from "../../Containers/ContainerRow/ContainerDirection";
import "./CardReqPhotog.scss";
import {statusEnum} from '../../../../assets/statics/reviews-step.enum';
import {BackgroundImg} from 'react-cloudimage-responsive';
import PrimaryBtn from '../../Buttons/Template/PrimaryBtn';
import {config} from '../../../../environments';
import {useHttpClient} from '../../../../hooks/http-hook';
import {cloudimageConfig} from '../../../../assets/statics/cloud-image';
import TertiaryBtn from '../../Buttons/Template/TertiaryBtn';
import useReadableAddress from '../../../../hooks/useReadableAddress';

const CardReqPhotog = (props) => {
    const {sendRequest} = useHttpClient();
    const [photographer, setPhotographer] = useState();

    const {getAddress, addressTypeEnum} = useReadableAddress();

    useEffect(() => {
        if (props.photographerId) {
            retrievePhotographer();
        }
    }, []);

    const retrievePhotographer = async () => {
        const userEndpoint = `${config.baseUrlDev}/photogrpher/no-control/${props.photographerId}`;
        return await sendRequest(
            userEndpoint, 'GET', null, {'Content-Type': 'application/json'}
        )
            .then((data) => setPhotographer({...data, photographerId: props.photographerId}))
            .catch((e) => console.warn({e}));
    }

    return (
        <div className="photographer-card">
            <ContainerDirection
                className="card-req"
                direction="column"
                width="272px"
                minHeight="388px"
            >
                <BackgroundImg src={photographer?.userId?.avatarFileUrl
                    ? photographer?.userId?.avatarFileUrl
                    : props.item?.userId?.avatarFileUrl
                        ? props.item?.userId?.avatarFileUrl
                        : props.item?.avatarFileUrl
                            ? props.item?.avatarFileUrl
                            : props.requestData?.avatarFileUrl
                                ? props.requestData?.avatarFileUrl
                                : cloudimageConfig.baseURL + props.backgroundImage}
                               width={272}
                               height={272}
                               style={{width: "100%", height: "272px"}}
                               className="profile-image"
                >
                    {(!props.type || props.type !== 'reviewing') &&
                    <PrimaryBtn
                        className="btn-vdetails"
                        parentAction={() => (
                            props.onClickDetails(
                                photographer ? photographer : props.item,
                                props.requestData
                            )
                        )}
                    >
                        View details
                    </PrimaryBtn>
                    }
                </BackgroundImg>
                <p className="card-content-name phantom-bold" style={{marginBottom: "0"}}>
                    {photographer
                        ? `${photographer?.userId?.firstName} ${photographer?.userId?.lastName}`
                        : props.item?.companyName
                            ? props.item?.companyName
                            : props.item?.userId?.firstName
                                ? `${props.item?.userId?.firstName} ${props.item?.userId?.lastName}`
                                : props.requestData?.firstName
                                    ? `${props.requestData?.firstName} ${props.requestData?.lastName}`
                                    : `${props.item?.firstName} ${props.item?.lastName}`
                    }
                </p>
                {(photographer?.userId?.address || props.item?.userId?.address || props.requestData?.address || props.item?.address) &&
                <ContainerDirection direction="row" height="36px" width="100%">
                    <CircleLoc/>
                    <p className="card-content-location typo-size-small" style={{marginBottom: "0"}}>
                        Currently in
                        <br/>
                        {photographer
                            ? getAddress(photographer?.userId?.address, addressTypeEnum.SHORT)
                            : props.item?.userId?.address
                                ? getAddress(props.item?.userId?.address, addressTypeEnum.SHORT)
                                : props.requestData?.address
                                    ? getAddress(props.requestData?.address, addressTypeEnum.SHORT)
                                    : getAddress(props.item?.address, addressTypeEnum.SHORT)
                        }
                    </p>
                </ContainerDirection>
                }
                {props.request && (
                    <>
                        {props.status !== statusEnum.DECLINED &&
                        <TertiaryBtn className="btn-decline btn-default-width btn-default-height"
                                     parentAction={() => props.parentAction
                                         ? props.parentAction(props.requestData ? props.requestData?._id : props.item?._id, statusEnum.DECLINED)
                                         : null
                                     }
                        >
                            Decline
                        </TertiaryBtn>}
                        {props.status !== statusEnum.ACCEPTED &&
                        <PrimaryBtn className="btn-accept btn-default-width btn-default-height"
                                    parentAction={() => props.parentAction
                                        ? props.parentAction(props.requestData ? props.requestData?._id : props.item?._id, statusEnum.ACCEPTED)
                                        : null
                                    }
                        >
                            Accept
                        </PrimaryBtn>
                        }
                    </>
                )}
            </ContainerDirection>
        </div>
    );
};
export default CardReqPhotog;
